import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Rechtsgebieden from "../views/Rechtsgebieden.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/rechtsgebieden",
    name: "Rechtsgebieden",
    component: Rechtsgebieden
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return setTimeout(() => {
        window.scrollTo({
          top: document.querySelector(to.hash).offsetTop,
          behavior: "smooth"
        });
      }, 200);
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
