<template>
  <div class="home">
    <!--Nav-->
    <Header />

    <div class="carousel relative">
      <div class="carousel-inner relative overflow-hidden w-full">
        <!--Slide 1-->
        <input
          class="carousel-open"
          type="radio"
          id="carousel-1"
          name="carousel"
          aria-hidden="true"
          hidden=""
          checked="checked"
        />
        <div class="carousel-item absolute" style="height: 25rem">
          <div
            class="h-full w-full mx-auto flex pt-6 md:pt-0 items-center bg-cover bg-center justify-center"
            style="background-image: url('zwartwit.jpg')"
          >
            <div class="container p-8 flex justify-items-end flex-col">
              <p
                class="text-main-300 text-bold md:text-5xl mr-2 text-right text-3xl my-4 break-words w-auto"
              >
                <span class="text-white">Heleen Helleman</span>
              </p>
              <p
                class="text-white text-bold md:text-2xl mr-2 text-right text-2xl my-4 break-words w-auto"
              >
                Advocaat in strafrecht en jeugd(straf)recht
              </p>
              <a
                href="tel:0031620657193"
                class="rounded-lg px-5 md:px-8 py-3 md:py-4 bg-white hover:bg-gray-200 text-gray-800 font-semibold leading-tight shadow-md ml-auto"
                >Bel nu! <span class="text-sm">06 20657193</span></a
              >
              <iframe class="ml-auto" frameborder="0" allowtransparency="true" src="https://www.klantenvertellen.nl/retrieve-widget.html?button=true&lang=nl&tenantId=99&locationId=1067059" width="150" height="195"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="bg-white py-3 px-8 md:px-32">
      <div
        class="container mx-auto flex flex-row items-center flex-wrap pt-4 pb-6"
      >
        <div class="py-1 text-xl text-left  w-full md:w-5/5" style="top:0">
          <p class="mb-5">
            Heb je een probleem met politie,  justitie of jeugdhulpverleners, heb je vragen over je rechten en hoe je op te stellen ? 
            Bel direct, dan kunnen we jouw probleem bespreken.

            Mijn naam is Heleen Helleman, ik ben ruim 25 jaar advocaat en gespecialiseerd in het Strafrecht en het Jeugd(straf)recht. Mijn kracht is een persoonlijke, deskundige aanpak en mijn doel is jouw belang zo goed mogelijk te behartigen.

            Contact kan je met mij opnemen via telefoon, whats app of een email.
            Tijdens ons eerste contact bespreken we de inhoud van je zaak als ook de financiële kant ervan en maken we een plan van aanpak.

            Mijn uurtarief bedraagt € 150,00 ex BTW.
            Ook met gefinancierde rechtsbijstand ben je van harte welkom. Voor meer informatie verwijs ik je naar: www.rvr.org
          </p>
        </div>
      </div>
    </section>

    <section
      class="bg-gray-100 py-1 bg-cover bg-center px-8 md:px-32"
      style="background-image: url('lawful.jpg')"
    >
      <div
        class="container my-6 mx-auto flex flex-row items-center flex-wrap pt-4 pb-6 opacity-100"
        style="background: rgba(0, 0, 0, 0.45);color:white"
      >
        <div
          class="px-6 py-1 text-left w-full md:w-5/5 flex-grow"
          style="min-height:100%"
        >
          <h1 class="font-bold text-2xl my-3 ">Contact</h1>
          <p class="text-base mb-5">
            Bereikbaar iedere werkdag tussen 9:00 - 18:00.
          </p>
          <b>Telefoon</b>
          <p class="mb-5">
            <a href="tel:0031620657193">+31 6 20657193</a>
          </p>
          <b>E-mail</b>
          <p class="mb-5">
            <a href="mailto:heleen@advocatenkantoorhelleman.nl">heleen@advocatenkantoorhelleman.nl</a>
          </p>
        </div>
      </div>
    </section>

    <section id="over" class="bg-white py-3 px-8 md:px-32">
      <div
        class="container mx-auto flex flex-row items-center flex-wrap pt-4 pb-12"
      >
        <div class="py-1 text-xl text-left w-full">
          <h1 class="font-bold text-2xl my-3">
            Over
          </h1>
          <p class="mb-5">
            Mijn naam is Heleen H.M. Helleman, ik ben advocaat sinds 1996. Door de jaren heen heb ik mij gespecialiseerd in het strafrecht en het Jeugd(straf/civiel)recht. Omdat het jeugdrecht mij erg aan het hart ligt heb ik de post academische specialisatie opleiding De Jeugdrecht Advocaat gevolgd en ben ik lid van de Vereniging voor Nederlandse Jeugdrechtadvocaten.
            Ieder zaak krijgt mijn volle aandacht, net als mijn cliënten en voer ik een creatieve en grondige verdediging waarbij het menselijk aspect nimmer uit het oog wordt verloren.
            Ik heb Nederlands Recht gestudeerd aan de Vrije Universiteit te Amsterdam. Hierna  ben ik dertien jaar werkzaam geweest in Amsterdam bij advocatenkantoor Rozendaal. Daar ben ik begonnen in de algemene praktijk maar heb mij vervolgens gespecialiseerd in het straf-en jeugdrecht. In 2009 ben ik voor mijzelf begonnen en heb ik Advocatenkantoor Helleman opgericht. Sindsdien heb ik aan twee verschillende kostenmaatschappen deelgenomen, zijnde  advocatenkantoor Hellemons & Helleman, dit kantoor is opgegaan in het advocatenkantoor Gooische Meesters. Sinds 2013 werk ik onder mijn eigen kantoornaam.
          </p>
        </div>
      </div>
    </section>

    <footer
      class="bg-gray-800 text-cognac-light text-center p-6 text-xs text-white"
    >
      © 2023 Helleman Advocaten | Realisatie:
      <a href="https://egidius.io" target="_blank" class="hover:underline"
        >Egidius IT</a
      >
    </footer>
  </div>
</template>

<script>
import Header from "../components/Header";

export default {
  name: "Home",
  components: {
    Header
  }
};
</script>
