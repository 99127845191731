<template>
  <div class="home">
    <!--Nav-->
    <Header />

    <div class="carousel relative">
      <div class="carousel-inner relative overflow-hidden w-full">
        <!--Slide 1-->
        <input
          class="carousel-open"
          type="radio"
          id="carousel-1"
          name="carousel"
          aria-hidden="true"
          hidden=""
          checked="checked"
        />
        <div
          class="carousel-item absolute h-0 bg-cover bg-center justify-center"
          style="height: 22rem;background-image: url('tingey-injury.jpg')"
        >
          <div
            class="h-full w-full mx-auto flex pt-6 md:pt-0 items-center justify-center"
            style="background: rgba(0, 0, 0, 0.5);"
          >
            <h1 class="text-white text-4xl">Rechtsgebieden</h1>
          </div>
        </div>
      </div>
    </div>

    <!--	 
Alternatively if you want to just have a single 
<section class="w-full mx-auto bg-nordic-gray-light flex pt-12 md:pt-0 md:items-center bg-cover bg-right" style="max-width:1600px; height: 32rem; background-image: url('https://images.unsplash.com/photo-1422190441165-ec2956dc9ecc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600&q=80');">
	<div class="container mx-auto">
		<div class="flex flex-col w-full lg:w-1/2 justify-center items-start  px-6 tracking-wide">
			<h1 class="text-black text-2xl my-4">Stripy Zig Zag Jigsaw Pillow and Duvet Set</h1>
			<a class="text-xl inline-block no-underline border-b border-gray-600 leading-relaxed hover:text-black hover:border-black" href="#">products</a>
		</div>
	  </div>
</section>
-->
    <section class="bg-white py-2">
      <div class="container mx-auto flex flex-row items-center flex-wrap py-4">
        <div class="px-6 py-1 text-xl text-left w-full">
          <div
            class="mb-5 prose lg:prose-xl m-auto border border-gray-200 p-4 bg-gray-100 rounded"
          >
            <h3 style="color:#889176;">Het Strafrecht</h3>
            <div>
              Bent u een verdachte ? 
              In het strafrecht staan de termen ‘verdachte’ en ‘verdenking’ centraal. 
              Je bent verdachte wanneer de politie je verdenkt van het (helpen en/of willen) plegen van een strafbaar feit.  
              Jij of je kind zijn een verdachte wanneer je bent:
              aangehouden / bent uitgenodigd voor verhoor bij de politie / een strafbeschikking, een dagvaarding of een oproep voor een officierszitting hebt ontvangen / wanneer je rijbewijs is ingenomen of je een rijontzegging heeft gekregen. 
              Dit zijn DE momenten om direct advies in te winnen.
              Ben jij of je kind aangehouden dan hebben jullie altijd recht op overleg met een advocaat voor het politieverhoor (we bespreken de zaak en je opstelling tijdens het verhoor, zwijgen of verklaren) en bijstand tijdens het verhoor. Mijn bezoek op het politiebureau is bijna altijd kosteloos.
              Ben je schriftelijk uitgenodigd voor een verhoor dan mag je altijd voor het verhoor overleggen met een advocaat en een advocaat meenemen naar het verhoor.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-white py-2">
      <div class="container mx-auto flex flex-row items-center flex-wrap py-4">
        <div class="px-6 py-1 text-xl text-left w-full">
          <div
            class="mb-5 prose lg:prose-xl m-auto border border-gray-200 p-4 bg-gray-100 rounded"
          >
            <h3 style="color:#889176;">Het jeugdstrafrecht</h3>
            <div>
              JE HEBT ALS MINDERJARIGE ALTIJD RECHT OP GRATIS BIJSTAND VAN EEN ADVOCAAT.
              En maak daar gebruik van. Met je advocaat bespreek je de inhoud van je zaak en bepaal je je houding tijdens het verhoor. Soms is verklaren beter en soms zwijgen. De politie zegt natuurlijk altijd uit dat verklaren het beste is en dat je dan sneller naar huis mag. Maar dat ik niet altijd waar en op de langere termijn kan je door verklaart te hebben zelf zorgen voor een eigen veroordeling.
              Tijdens het verhoor mag naast de advocaat ook een ouder aanwezig zijn.

              In het jeugdstrafrecht gaat het om minderjarige kinderen (van 12 jaar tot 18 jaar en soms tot 23 jaar). 

              De officier van justitie bepaalt of hij je gaat vervolgen en op welke wijze. De officier heeft hiertoe meerdere mogelijkheden.

            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-white py-2">
      <div class="container mx-auto flex flex-row items-center flex-wrap py-4">
        <div class="px-6 py-1 text-xl text-left w-full">
          <div
            class="mb-5 prose lg:prose-xl m-auto border border-gray-200 p-4 bg-gray-100 rounded"
          >
            <h3 style="color:#889176;">Het jeugdrecht</h3>
            <div>
              Het jeugdrecht is een aparte specialisatie. 
Helaas is veelal de wettelijk verplichte bijstand door een advocaat in het jeugdrecht door bezuinigingen verdwenen. Dit betekend echter niet dat jij en je kind geen recht hebben op gefinancierde rechtsbijstand. Helaas weten veel ouders en minderjarige dit niet en gaan zij zonder deskundige bijstand het gesprek aan. Dit is niet verstandig omdat een ondertoezichtstelling en een uithuisplaatsing ernstige gevolgen kunnen hebben voor het gezag van de ouders en mogelijk terugplaatsing naar huis.
Hier is daarom van heel groot belang dat zodra jij en je kind (eren) te maken krijgen met hulpverlening  je contact opneemt met een advocaat welke gespecialiseerd is in het jeugdrecht. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer
      class="bg-gray-800 text-cognac-light text-center p-6 text-xs text-white"
    >
      © 2020 Helleman Advocaten | Realisatie:
      <a href="https://egidius.io" target="_blank" class="hover:underline"
        >Egidius IT</a
      >
    </footer>
  </div>
</template>

<script>
import Header from "../components/Header";

export default {
  name: "Home",
  components: {
    Header
  },
  data() {
    return {
      items: []
    };
  },
  methods: {
    async getData() {
      const aboutRes = await fetch(
        "https://directus.advocatenkantoorhelleman.nl/items/Strafrecht"
      );
      const aboutData = await aboutRes.json();
      this.items = aboutData.data
    }
  },
  beforeMount() {
    this.getData();
  }
};
</script>
